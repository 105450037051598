import React, { useEffect } from "react";
import * as THREE from "three";
import { Water } from "three/examples/jsm/objects/Water";
import { Sky } from "three/examples/jsm/objects/Sky";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./App.css";

const ShaderComponent = () => {
  useEffect(() => {
    let container;
    let camera, scene, renderer;
    let water, sun, model;

    const init = () => {
      container = document.getElementById("container");

      renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 0.5;
      container.appendChild(renderer.domElement);

      scene = new THREE.Scene();

      camera = new THREE.PerspectiveCamera(
        55,
        window.innerWidth / window.innerHeight,
        1,
        20000
      );
      camera.position.set(30, 30, 100);
      camera.lookAt(0, 0, 0); // Ensure the camera is looking at the center of the scene

      sun = new THREE.Vector3();

      const waterGeometry = new THREE.PlaneGeometry(10000, 10000);

      water = new Water(waterGeometry, {
        textureWidth: 512,
        textureHeight: 512,
        waterNormals: new THREE.TextureLoader().load(
          "https://threejs.org/examples/textures/waternormals.jpg",
          function (texture) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          }
        ),
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x001e0f,
        distortionScale: 3.7,
        fog: scene.fog !== undefined,
      });

      water.rotation.x = -Math.PI / 2;
      scene.add(water);

      const sky = new Sky();
      sky.scale.setScalar(10000);
      scene.add(sky);

      const skyUniforms = sky.material.uniforms;

      skyUniforms["turbidity"].value = 10;
      skyUniforms["rayleigh"].value = 2;
      skyUniforms["mieCoefficient"].value = 0.005;
      skyUniforms["mieDirectionalG"].value = 0.8;

      const parameters = {
        elevation: 0,
        azimuth: 180,
      };

      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      let renderTarget;

      const updateSun = () => {
        const phi = THREE.MathUtils.degToRad(90 - parameters.elevation);
        const theta = THREE.MathUtils.degToRad(parameters.azimuth);

        sun.setFromSphericalCoords(1, phi, theta);

        sky.material.uniforms["sunPosition"].value.copy(sun);
        water.material.uniforms["sunDirection"].value.copy(sun).normalize();

        if (renderTarget !== undefined) renderTarget.dispose();

        renderTarget = pmremGenerator.fromScene(sky);
        scene.environment = renderTarget.texture;
      };

      updateSun();

      // Load the GLTF model
      const loader = new GLTFLoader();
      loader.load(
        "/pen15/scene.gltf",
        (gltf) => {
          model = gltf.scene;
          model.position.set(0, 0, 0); // Adjust position as needed
          model.scale.set(75, 75, 75); // Adjust scale as needed
          scene.add(model);
        },
        undefined,
        (error) => {
          console.error("An error occurred loading the model", error);
        }
      );

      window.addEventListener("resize", onWindowResize);
    };

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const animate = () => {
      requestAnimationFrame(animate);
      render();
    };

    const render = () => {
      const time = performance.now() * 0.001;

      if (model) {
        model.position.y = Math.sin(time) * 20 + 5;
        model.rotation.x = time * 0.5;
        model.rotation.z = time * 0.51;
      }

      water.material.uniforms["time"].value += 1.0 / 60.0;

      renderer.render(scene, camera);
    };

    init();
    animate();
  }, []);

  return <div id="container"></div>;
};

export default ShaderComponent;
