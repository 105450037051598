import React from "react";
import ShaderComponent from "./ShaderComponent";
import { Analytics } from "@vercel/analytics/react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div id="container"></div>
      <div className="main-wrapper">
        <div className="container-large page-padding">
          <div className="section-teaser">
            <div className="teaser-component">
              <div className="hero-container">
                <h2 className="hero glitch layers" data-text="COCKFLOAT">
                  <span>COCKFLOAT</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="grid-mask"></div>
      </div>
      <ShaderComponent />
      <Analytics />
    </div>
  );
}

export default App;
